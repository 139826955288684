import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themeConfigs } from '@upgrade/themes';
import GlobalStyles from '@upgrade/styles/GlobalStyles';
import { SkipToContent } from '@upgrade/react-components';
import { I18nProvider } from '@lingui/react';
import i18n, { activateLocale } from './i18n';
import { OptimizelyProvider } from './utils/optimizely';
import MobileHeaderHandler from './components/MobileHeaderHandler';

activateLocale();

// eslint-disable-next-line import/prefer-default-export
export const wrapStyles = ({ element }) => {
  return (
    <I18nProvider i18n={i18n}>
      <ThemeProvider theme={themeConfigs.upgrade}>
        <GlobalStyles />
        <MobileHeaderHandler />
        <SkipToContent />
        <OptimizelyProvider>{element}</OptimizelyProvider>
      </ThemeProvider>
    </I18nProvider>
  );
};
